<template>
  <b-nav-item @click="toggleSkin">
    <feather-icon size="21" :icon="icon[skin]" />
  </b-nav-item>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { BNavItem } from "bootstrap-vue";

export default {
  data: () => ({
    theme: ["light", "semi-dark", "dark"],
  }),
  computed: {
    icon() {
      return {
        light: "SunIcon",
        "semi-dark": "SunsetIcon",
        dark: "MoonIcon",
      };
    },
  },
  setup() {
    const { skin } = useAppConfig();
    return { skin };
  },
  methods: {
    toggleSkin() {
      const select = this.theme.indexOf(this.skin);
      const nextItem = select + 1 > this.theme.length - 1 ? 0 : select + 1;
      this.skin = this.theme[nextItem];
    },
  },
  components: {
    BNavItem,
  },
};
</script>
