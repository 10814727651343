<template>
  <b-nav-item @click="toggleLayout">
    <feather-icon size="21" :icon="icon[layoutType]" />
  </b-nav-item>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { BNavItem } from "bootstrap-vue";

export default {
  data: () => ({
    theme: ["light", "semi-dark", "dark"],
  }),
  computed: {
    icon() {
      return {
        horizontal: "SidebarIcon",
        vertical: "CreditCardIcon",
      };
    },
  },
  setup() {
    const { layoutType } = useAppConfig();
    return { layoutType };
  },
  methods: {
    toggleLayout() {
      this.layoutType = this.layoutType === 'horizontal' ? 'vertical' : 'horizontal'
      console.log('layout type : ', this.layoutType)
    },
  },
  components: {
    BNavItem,
  },
};
</script>
