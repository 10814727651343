<template>
  <div v-if="isUser" class="search-history" :class="classes">
    <b-input-group>
      <b-form-input
        v-model="text"
        @keyup.enter="search"
        class="search-history-input"
        size="sm"
        placeholder="Search IMEI/Serial..."
      />
      <b-input-group-append v-if="text">
        <b-button @click="search" size="sm" variant="outline-primary">
          <feather-icon icon="SearchIcon" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import {
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
export default {
  props: ["classes"],
  data: () => ({
    text: null,
  }),
  computed: {
    isUser() {
      return this.$store.getters.isUser;
    },
  },
  methods: {
    search() {
      window.location = `/user/orders?search=${this.text}&col=3`;
    },
  },
  components: {
    BInputGroupAppend,
    BInputGroup,
    BFormInput,
    BButton,
  },
};
</script>

<style scoped>
.search-history {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.search-history-input {
  max-width: 180px;
}
</style>