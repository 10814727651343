<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav menu navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center d-lg-flex">
      <LayoutToggler class="d-none d-xl-flex none-list" />
      <dark-Toggler class="none-list" />
    </div>

    <b-navbar-nav class="nav-actions nav d-flex align-items-center ml-auto">
      <SearchHistory classes="d-none d-md-flex" />
      <router-link to="/user/deposit">
        <h3 class="balance mx-1 mb-0">{{ balance }} $</h3>
      </router-link>
      <!-- Notification -->
      <notification-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BAlert,
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownText,
  BDropdownDivider,
} from "bootstrap-vue";
import NotificationDropdown from "@core/layouts/components/app-navbar/components/NotificationDropdown.vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import LayoutToggler from "@core/layouts/components/app-navbar/components/LayoutToggler.vue";
import SearchHistory from "@/components/helpers/SearchHistory.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    SearchHistory,
    BAlert,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownText,
    // Navbar Components
    NotificationDropdown,
    DarkToggler,
    LayoutToggler,
  },
  computed: {
    ...mapGetters(["balance"]),
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.none-list {
  list-style: none;
}
.username {
  text-transform: capitalize;
}
.credit {
  display: block;
  font-weight: bold;
}
.nav-actions {
  flex: 1;
}
.nav {
  justify-content: flex-end;
}
</style>
