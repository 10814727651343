import api from "@axios";

class Notify {
    // Get All Notify users (for admin)
    static async all() {
        const { data } = await api.get(`/notify`)
        return data.data
    }
    // Get All my notifications
    static async my() {
        let totalSeen = 0;
        const { data } = await api.get(`/notify/my`)
        const list = data.data
        list.forEach((n) => {
            if (!n.is_seen) totalSeen += 1;
        });
        return {
            list,
            totalSeen
        }
    }
    // Send Notify (for admin)
    static async send(formData) {
        const newNotify = await api.post(`/notify`, formData);
        return newNotify
    }
    // inactive Notify (for admin)
    static async delete(id) {
        await api.delete(`/notify/${id}`)
    }
    // Read Notify
    static async readAll() {
        await api.put(`/notify/read-all`)
    }
    // Clear All notifications
    static async clearAll() {
        await api.put(`/notify/clear-all`)
    }
}

export default Notify;